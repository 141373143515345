<template>
    <div class="myPage">
        <div class="topPic">
            <img src="../../assets/avatar.jpeg" alt="头像" />
            <div class="nameBox" v-if='isLogin'>
                <span class="name">{{infos.name}}</span>
                <span>{{infos.mobile}}<i class="exit" @click="goLoginPage">退出</i></span>
            </div>
            <div v-else >
                <span class="login" @click="goLoginPage"> 点击登录</span>
                <span class="login" @click="goLoginPage">退出登录</span>
            </div>

        </div>
        <div class="infoBox">
            <ul class="list">
                <template v-for="(item ,index) in list">
                    <li :key='index' @click="goPage(item.path)">
                        <div>
                        <img :src="item.icon" />
                        <span>{{item.name}}</span>
                        </div>
                        <Icon class="arrow" name='arrow' color='#666' size='14'/>
                    </li>
                </template>
            </ul>
        </div>
        <HomeBtns/>
    </div>
</template>

<script>
import myIcon1 from "../../assets/myIcon1.png"
import myIcon2 from "../../assets/myIcon2.png"
import myIcon3 from "../../assets/myIcon3.png"
import myIcon4 from "../../assets/myIcon4.png"
import myIcon5 from "../../assets/pay.png"
import {Icon} from "vant";
import HomeBtns from "../../components/homeBtns.vue"
import {getUserInfo} from "@/api/my.js";
import {setCookie,getCookie} from "@/utils/util.js"
import { getMemberInfo } from "@/api/member.js";

    export default {
        name:'my',
        components:{
            Icon,
            HomeBtns
        },
        data(){
            return{
                infos:{},
                isLogin:true,
                list:[
                    {icon:myIcon1,name:'我的培训',path:'/myMeetList'},
                    {icon:myIcon2,name:'我的发票',path:'/myInvoice'},
                    // {icon:myIcon3,name:'会员缴费',path:'/memberPay'},
                    {icon:myIcon3,name:'会员缴费',path:'/memberType'},
                    {icon:myIcon5,name:'会员缴费记录',path:'/memberRecords'},
                    {icon:myIcon4,name:'我的资料',path:'/myData'},

                ],
                memberInfo:{}
            }
        },
        created(){
            let clientId=localStorage.getItem('clientId')||'';
            if(clientId){
            //     console.log('...')
                this.getUserInfo(clientId);
                // this.getMemberInfo(clientId)
                // this.init()
            }else {
                this.goLoginPage()
            }


        },
        // mounted(){
        //     let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/my`};
        //     let postData=JSON.stringify(mchData)
        //     parent.postMessage(postData, 'https://payapp.weixin.qq.com')
        // },
        methods:{
            // exit(){
            //     this.$router.replace("/login");
            // },
            init(){
                let arr=['13854159795','15300086621','19863456506','18454155250','13520780676'];

                if(this.infos.mobile){
                    if(arr.includes(this.infos.mobile)){
                        this.list=[
                            {icon:myIcon1,name:'我的培训',path:'/myMeetList'},
                            {icon:myIcon2,name:'我的发票',path:'/myInvoice'},
                            {icon:myIcon3,name:'会员缴费',path:'/memberPay'},
                            {icon:myIcon4,name:'我的资料',path:'/myData'},
                        ]
                    }

                }
            },
            getUserInfo(clientId){
                getUserInfo({clientId}).then(res=>{
                    if(res.status=="S"){
                        if(res.data){
                        this.infos=res.data;
                        this.isLogin=true;
                        // this.init()

                        }else{
                            this.isLogin=false;
                            localStorage.setItem('clientId','');
                            setCookie("clientId", '')
                        }
                    }else{
                        this.isLogin=false;
                        localStorage.setItem('clientId','');
                        setCookie("clientId", '')
                    }
                })
            },
            goLoginPage(){
                localStorage.setItem('clientId','');
                setCookie("clientId", '')
                this.$router.replace("/login?from=my");
            },
            goPage(path){
                if(!this.isLogin){
                    this.$router.replace("/login?from=my");
                }else{
                    if(path=='/myData'){
                        this.$router.push({name:'myData',params:this.infos});
                    }else if (path == "memberType") {
                        let type=this.memberInfo.memberType?this.memberInfo.memberType:'0'
                        this.$router.push(`/memberType?id=${this.memberInfo.id}&memberType=${type}`);
                    }
                    else{
                        this.$router.push(path);
                    }
                }
            },
            getMember(id) {
                getMemberInfo({id}).then(res=>{
                    if(res.status=="S"){
                        this.memberInfo=res.data
                        localStorage.setItem("mobile",res.data.mobile)
                    }
                })
            },
        }
    }
</script>

<style lang="less" scoped>
.myPage{
    background:#f5f5f5;
    height: 100vh;
    position: relative ;
    overflow: hidden;
}
.topPic{
    background: url('../../assets/loginBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 2.35rem;
    padding-top: 0.3rem;
    box-sizing: border-box;
    .exit{
        color:red;
        margin-left: 10px;
        font-size: 12px;
        font-style: normal;
    }
    img{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.35rem;
    }
    .nameBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 13px;
        span{
            margin-top:0.12rem;
        }
        .name{
            font-size: 16px;
            margin-top: 0.2rem;
            font-weight: bold;
        }

    }
    .login{
        display: inline-block;
        width: 0.85rem;
        height: 0.3rem;
        border: 1px solid #fff;
        border-radius: 5px;
        font-size: 15px;
        color: #fff;
        text-align: center;
        line-height: 0.3rem;
        margin-top: 0.26rem;
        margin-right:5px;
    }
}
.infoBox{
    padding:0 0.12rem;
    margin-top: -0.4rem;
    ul{
        width: 100%;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.08);
        padding:0 0.3rem;
        box-sizing: border-box;
        li{
            height: 0.6rem;
            border-bottom: 1px solid #eee;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #333;
            &:last-child{
                border-bottom: 0px solid #eee;
            }
            div{
                display: flex;
                flex-direction: row;
                align-items: center;
                img{
                    width: 0.17rem;
                    margin-right: 0.22rem;
                }
            }



        }
    }
}
</style>
